












import Vue from 'vue'
import Nav from '@/components/Nav.vue'
import ProductForm from '@/components/ProductForm.vue'

export default Vue.extend({
  components: { Nav, ProductForm },
  methods: {
    productCreated() {
      this.$router.push({
        name: 'products',
      })
    },
  },
})
